import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';

import { rhythm } from '../utils/typography';

const bioQuery = graphql`
  query BioQuery {
    avatar: file(absolutePath: { regex: "/davidtaboas-fotoperfil.jpg/" }) {
      childImageSharp {
        fixed(width: 50, height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
        social {
          twitter
        }
      }
    }
  }
`;

const Bio = () => {
  const {
    avatar: {
      childImageSharp: { fixed: avatar },
    },
    site: {
      siteMetadata: { author },
    },
  } = useStaticQuery(bioQuery);

  return (
    <div
      style={{
        display: `flex`,
        marginBottom: rhythm(2.0),
      }}
    >
      <Image
        fixed={avatar}
        alt={author}
        style={{
          marginRight: rhythm(1 / 2),
          marginBottom: 0,
          minWidth: 50,
          borderRadius: `100%`,
        }}
        imgStyle={{
          borderRadius: `50%`,
        }}
      />
      <p>
        «Ingeniero de software». Productos digitales e innovación.
        {` `}
        Puedes contactarme en <strong>me@davidtaboas.com</strong>
      </p>
    </div>
  );
};

export default Bio;
