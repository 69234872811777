import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';

import BioFreelance from '../components/BioFreelance';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import { rhythm } from '../utils/typography';
import Gallery from '../components/Gallery';

const infoQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    logos: allFile(filter: { absolutePath: { regex: "/logo-/" } }) {
      edges {
        node {
          childImageSharp {
            fixed(width: 100) {
              ...GatsbyImageSharpFixed
            }
          }
          name
        }
      }
    }
    portfolios: allFile(filter: { absolutePath: { regex: "/portfolio-/" } }) {
      edges {
        node {
          childImageSharp {
            fixed(width: 250) {
              ...GatsbyImageSharpFixed
            }
          }
          name
        }
      }
    }
  }
`;

const Home = ({ location }) => {
  const {
    site: {
      siteMetadata: { title: siteTitle },
    },
    logos: { edges: logos },
    portfolios: { edges: portfolios },
  } = useStaticQuery(infoQuery);

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title="Ingeniero de software"
        keywords={[
          `david táboas`,
          `javascript`,
          `react`,
          `desarrollador freelance`,
          `desarrollo react`,
          `formaciones react`,
          `cursos react a empresas`,
          `arquitecto software`,
          `ingeniero javascript`,
          'javascript engineer',
          'desarrollador senior',
          'desarrollador senior react',
        ]}
      />
      <BioFreelance />

      <h2>Qué hago</h2>
      <p>
        Soy «Ingeniero de software» en productos digitales. Todo mi tiempo lo he dedicado a la
        arquitectura, desarrollo y gestión de este tipo de soluciones y de sus equipos.
      </p>

      <Gallery>
        {logos.map(logo => (
          <Image
            key={logo.node.name}
            style={{ flexShrink: 0, margin: '10px', filter: 'grayscale(100%)' }}
            fixed={logo.node.childImageSharp.fixed}
          />
        ))}
      </Gallery>

      <h2>Contrataciones</h2>
      <p>
        No dudes en contactarme en <strong>me@davidtaboas.com</strong>. Estos son algunos de los
        aspectos en los que podemos colaborar:
      </p>
      <ul>
        <li>
          <strong>Formaciones en React</strong> y su ecosistema a equipos o desarrolladores.
        </li>
        <li>
          Asesoramiento en <strong>productos digitales</strong>, PropTech, Retail, análisis de
          soluciones técnicas...
        </li>
        <li>
          <strong>Desarrollo de soluciones</strong> freelance o integrándome en el equipo de tu
          empresa. Javascript Engineer, React, Redux, aplicaciones progresivas (PWA),
        </li>
        <li>
          Asesoramiento y <strong>gestión de procesos de desarrollo</strong> en empresas: code
          reviews, CI/CD, guías de estilo...
        </li>
      </ul>

      <h2>Experiencia</h2>
      <p>
        Hace ya{' '}
        <strong>
          más de una década que empecé a hacer trabajos profesionales en el mundo digital
        </strong>
        . Antes me había interesado por el mundo de los blogs, el boom 2.0 y los medios digitales.
        En 2008 creé, junto a un amigo, una empresa que ayudaba a las pymes en la creación de su
        presencia digital. Estuvimos presentes en el{' '}
        <a
          href="https://www.red.es/redes/es/actuaciones/foro-internacional-de-los-contenidos-digitales"
          target="_blank"
          rel="noopener noreferrer"
        >
          FICOD 2008
        </a>
        . Los retos estaban en{' '}
        <strong>
          escuchar a los clientes, entender sus necesidades y desarrollar su presencia digital
        </strong>
        . Esto podía ser crear en un blog, un ecommerce, o una página web estática, dependiendo de
        lo que esperaban que el usuario encontrase en internet sobre ellos.
      </p>
      <p>
        Más adelante{' '}
        <strong>
          me establecí por cuenta propia, gestionando clientes y mezclando mi perfil de desarrollo
          con marketing, analíticas y SEO
        </strong>
        . El mayor propóstio era llegar al mayor número de usuarios, maximizando beneficios sin que
        fuese intrusivo para el usuario. En esta etapa el ecommerce estaba en pleno despegue en
        España y había que hacer muchas pruebas, simplificar procesos de compra, medir el uso de la
        web, mejorar el rendimiento para que con malas conexiones el usuario no cerrase la web, etc.
      </p>
      <p>
        Los últimos 5 años he pasado a un perfil de consultoría, trabajando con clientes como Bankia
        o Santander en{' '}
        <a href="https://opinno.com" target="_blank" rel="noopener noreferrer">
          Opinno
        </a>
        , siendo el <strong>enlace entre UX y front-end</strong>, gestionando la labor que se tenía
        que hacer para conseguir un resultado óptimo para el usuario.
      </p>
      <p>
        <strong>El mayor reto ha sido encabezar el equipo PropTech</strong> que ha desarrollado,
        primero, un aplicación inteligente para un parque empresarial y, segundo,{' '}
        <a href="https://edificio-virto.com" target="_blank" rel="noopener noreferrer">
          <strong>el primer edificio cognitivo de Europa</strong>
        </a>
        . Mi labor como Project Leader consitió en gestionar a todos los equipos implicados (UX, UI,
        front-end, back-end, ...) y definir y desarrollar todos los flujos de un usuario utilizando
        la aplicación del parque empresarial.
      </p>
      <p>
        Después de esta etapa pasé a formar parte de{' '}
        <a href="http://accedo.tv" target="_black" rel="noopener noreferrer">
          Accedo.tv
        </a>{' '}
        <strong>desarrollando una aplicación VOD</strong> destinada a funcionar en un{' '}
        <i>set-top-box</i> para Huawei e ICE. Podríamos decir que era{' '}
        <i>el Netflix de la TV de Costa Rica</i>.
      </p>
      <p>
        En esa etapa trabajé de forma muy cercada al equipo de producto de Accedo.tv, lo que me
        llamó la atención. Con esta motivación llegué a Teamwire, donde desarrollamos{' '}
        <i>el Whatsapp seguro para empresas</i>.
      </p>
      <p>
        Desde que dejé la fase de consultor,{' '}
        <strong>
          he vuelto a ser freelance para escuchar a los clientes de primera mano y poder ofrecerles
          la mejor solución
        </strong>
        .
      </p>
      <p>
        {' '}
        Un caso de éxito en el sector retail es una <i>Progressive Web App</i> para{' '}
        <a href="https://www.valassis.es/" target="_black" rel="noopener noreferrer">
          Valassis
        </a>
        , que funciona en los mayores supermercados de España.
      </p>
      <p>
        También he ayudado a desarrollar arquitecturas y soluciones en{' '}
        <a href="http://teamwire.eu">Teamwire</a>. En este caso una aplicación web que debe tener un
        comportamiento tan robusto como para poder sustituir a una aplicación de escritorio. Esto
        implica la máxima seguridad en cifrado/descifrado de datos y archivos, trabajo en offline,
        etc.
      </p>
      <p>
        Puedes ver mi trayectoria completa en{' '}
        <a href="http://linkedin.com/in/davidtaboas" target="_black" rel="noopener noreferrer">
          LinkedIn
        </a>
        .
      </p>

      <Gallery>
        {portfolios.map(portfolio => (
          <Image
            key={portfolio.node.name}
            style={{ flexShrink: 0 }}
            fixed={portfolio.node.childImageSharp.fixed}
          />
        ))}
      </Gallery>
      <br />
    </Layout>
  );
};

export default Home;
